.uspContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--color-neutral-white);
    font-size: var(--font-size-md);
    height: var(--font-size-md);

    & a {
        color: inherit;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
}

.wrapper {
    position: relative;
}

.accesibilityUspDisplay {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-neutral-white);
    width: clamp(200px, 50vw, 480px);
    z-index: var(--z-index-4);
    padding: var(--spacing-2);
    border-radius: 3px;
}

.liWrapper {
    list-style: none;
}