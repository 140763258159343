.menuPage {
    height: 100%;
    padding: var(--spacing-3) var(--spacing-4);
}

.header {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    justify-content: space-between;
    gap: var(--spacing-3);
    row-gap: var(--spacing-3);
    margin-bottom: var(--spacing-4);
    grid-template-areas:
        "back-button back-button"
        "headline header-link";
}

.backContainer {
    width: 100%;
    grid-area: back-button;
    margin-bottom: var(--spacing-2);

    & [class*='button'] {
        padding: 0;
    }
}