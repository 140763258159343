.n10Meta {
    background-color: var(--color-meta);
    color: var(--color-neutral-white);
}

.content{
    padding: 0 var(--spacing-2);
}

.link {
    text-decoration: none;
    color: var(--color-neutral-white);
    &:hover {
        color: var(--color-neutral-400);
    }
}

.innerContent {
    padding: var(--spacing-1) var(--spacing-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-2xs);
    height: 42px;
    &:only-child {
        margin: 0 auto;
    }
}

.list {
    display: none;
    list-style: none;
    margin-left: auto;
    gap: var(--spacing-5);
    @media --md {
        display: flex;
    }

    & a:last-child {
        margin-right: var(--spacing-1);
    }
}
