.searchBox {
    display: flex;
    position: absolute;
    flex: 1;
    order: 3;
    width: 100%;
    bottom: var(--search-height);
    left: 0;
    z-index: -1;

    @media --md {
        order: unset;
        width: unset;
        margin-left: unset;
        border-radius: 4px;
        border: 1px solid var(--color-grey-400);
        background: var(--color-neutral-200);
        border-right: none;
        position: relative;
        left: unset;
        bottom: unset;
        z-index: unset;
        transform: unset !important;
    }

    & > div {
        flex: 1;
    }

    & [class*='inputField'] {
        height: var(--search-height);
        border: none;
        background: var(--color-neutral-200);

        @media --md {
            border-radius: 4px;
        }

        & input {
            height: var(--search-height);
            background: transparent;
            font-size: var(--font-size-xs);

            &::placeholder {
                color: var(--color-neutral-black);
            }
        }
    }
}

.searchButton {
    background-color: var(--color-primary-500);
    color: white;
    width: var(--search-height);
    height: var(--search-height);
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
        background-color: var(--color-primary-500);

        & svg path {
            stroke: var(--color-neutral-black);
        }
    }

    @media --md {
        width: calc(var(--search-height) + 1px);
        height: calc(var(--search-height) + 1px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    & svg {
        width: 24px;
        height: 24px;
    }
}
