.menuWrapper {
    flex: 1;
}

.menu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--spacing-5);

    & ul {
        padding-left: 0;
    }
}
