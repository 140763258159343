@define-mixin breakpoint-max $width {
    @media (max-width: $width) {
        @mixin-content;
    }
}

@define-mixin breakpoint-min $width {
    @media (min-width: $width) {
        @mixin-content;
    }
}

@define-mixin breakpoint-min-max $min-width, $max-width {
    @media (min-width: $min-width) and (max-width: $max-width) {
        @mixin-content;
    }
}

@define-mixin cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@define-mixin scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.85), transparent;

    &::-webkit-scrollbar {
        display: block;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
        cursor: default;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius: 8px;
        border: 6px solid transparent;

        &:active,
        &:hover {
            background-color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
        }
    }
}

@define-mixin scrollbarThin {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.85), transparent;

    &::-webkit-scrollbar {
        display: block;
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f0f0f0;
        cursor: default;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius: 2px;
        &:active,
        &:hover {
            background-color: rgba(0, 0, 0, 0.65);
        }
    }
    -webkit-overflow-scrolling: touch;
}


@define-mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}