.list {
    list-style: none;
    display: flex;
    overflow: auto;
    padding: var(--spacing-3) var(--spacing-4);
    gap: var(--spacing-5);
    background-color: var(--color-grey-100);
    margin-bottom: 0;
}

.link {
    display: block;
    color: var(--color-neutral-black);
    font-size: var(--font-size-xs);
    padding: var(--spacing-3) 0;
    text-decoration: none;
}
