.list {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    padding: 0;
}

.dropDown {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    overflow-y: scroll;
    @mixin scrollbarThin;

    @media --reduced-motion {
        background-color: var(--color-neutral-white);
    }
}

.dropDownContent {
    display: grid;
    grid-template-columns: auto 22%;
    gap: var(--spacing-5);
}

.dropDownMaxWidth {
    margin: 0 auto;
    max-width: var(--content-max-width);
    width: 100%;
    padding: var(--spacing-6) 0 var(--spacing-5);
}

.dropDownOverlay {
    height: 0;
    width: 0;

    @media --md {
        content: '';
        background-color: var(--color-neutral-black);
        height: 100vh;
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        user-select: none;
        pointer-events: none;
    }
}
