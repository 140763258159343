.mainNavigation {
    top: 0;
    position: sticky;
    z-index: var(--z-index-3);
    border-top: 4px solid var(--color-neutral-black);
    height: calc(var(--header-height-desktop) + 1px);

    @media --md {
        border-top: none;
        border-bottom: 1px solid var(--color-grey-200);
        background-color: var(--color-neutral-white);
    }
}

.searchBoxPlaceholder {
    width: 100%;
    height: var(--search-height);
    pointer-events: none;
    user-select: none;

    @media --md {
        display: none;
    }
}

.logoContainer {
    padding-left: var(--spacing-2);

    @media (max-width: 340px) {
        padding-left: 0;
    }
}

.iconNav {
    display: grid;
    grid-auto-flow: column;
    margin-left: auto;
    position: relative;
    gap: var(--spacing-2);

    @media --xs {
        & [class*='iconOnly'] {
            width: var(--spacing-5);
        }
    }
}

.desktopNav {
    display: none;

    @media --md {
        height: var(--header-height-desktop);
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 25;
    }
}

.line {
    height: var(--search-height);
    border-left: 1px solid var(--color-neutral-300);
    display: none;
    width: 0;

    @media --md {
        display: block;
    }
}

.content {
    margin: 0 auto;
    max-width: var(--content-max-width);
    width: 100%;
    display: grid;
    align-items: center;
    gap: var(--spacing-6);
    grid-template-columns: auto auto;
    padding: 0 var(--spacing-2);
    row-gap: 0;
    background-color: var(--color-neutral-white);
    grid-template-rows: var(--header-height-desktop);

    @media --md {
        grid-template-columns: min-content auto min-content min-content;
        box-sizing: border-box;

        &.loggedIn {
            grid-template-columns: min-content auto min-content min-content min-content;
        }
    }

    @media (max-width: 340px) {
        gap: var(--spacing-3);
    }
}
