.navigationItem {
    display: flex;
    align-items: center;
    margin: auto;
    padding: var(--spacing-1) var(--spacing-3);
    color: var(--color-neutral-black);
    font-size: var(--font-size-xs);
    font-family: var(--font-family-body);
    text-decoration: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-radius: 4px;
    line-height: var(--spacing-5);
    white-space: nowrap;

    &.privateLink {
        font-weight: 600;
    }

    &:hover {
        background-color: var(--color-grey-200);
        color: currentcolor;
        text-decoration: none;
    }

    & svg {
        margin-left: var(--spacing-2);
        width: 24px;
        height: 24px;
    }
}

.active {
    background-color: var(--color-grey-200);
    color: currentcolor;
    text-decoration: none;
    opacity: 1;

    & + [class*='dropDown'] {
        border-top: 1px solid var(--color-grey-200);
    }
}
