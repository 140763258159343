.promotedContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-5);
}

.promotedItem {
    position: relative;
}

.itemOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: var(--spacing-3);
    padding-bottom: var(--spacing-3);
    display: flex;
    align-items: flex-end;
}

.info {
    color: white;
    margin: 0;
    font-weight: var(--font-weight-bold);
}

.coverLink {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
