.buttonPadding {
    padding: 0 var(--spacing-2);
    gap: 0;
}

.loginText {
    padding: 0 0 0 var(--spacing-2);
    display: none;

    @media --md {
        display: block;
    }
}

a.link, p.link {
    & > svg {
        margin-right: 8px;
    }

    @media --md {
        text-decoration: none;
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-regular);
        & p {
            color: var(--color-neutral-white);
        }
        &:hover {
            & p {
                color: var(--color-neutral-400) !important;
            }

            & > svg > path, & > svg > circle {
                stroke: var(--color-neutral-400) !important;
            }
        }

        & > svg {
            width: 17px !important;
            height: 17px !important;


            & > path, & > circle {
                stroke: var(--color-neutral-white) !important;
            }
        }
    }
}