.marketSelector {
    height: var(--spacing-2);
    grid-area: market;
    padding: 0 var(--spacing-2);
    display: flex;
    flex-direction: column;
}

.marketSelectorLabel {
    margin-top: 0;
}