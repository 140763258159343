.drawerContainer {
    pointer-events: none;

    &.open {
        pointer-events: auto;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(200px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(200px, 0, 0);
    }
}

.drawer {
    z-index: var(--z-index-2);
    position: fixed;
    width: min(100%, 100vw);
    bottom: 0;
    right: 0;
    background: var(--color-neutral-white);
    scroll-behavior: smooth;
    overflow-y: auto;
    animation-duration: 150ms;
    animation-timing-function: cubic-bezier(0.35, 0, 0.15, 1);
    animation-fill-mode: both;
    animation-name: fadeOut;

    &.open {
        animation-name: fadeIn;
    }
}

.backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-1);

    &.open {
        display: block;
    }
}

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
