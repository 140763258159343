.content {
    line-height: var(--line-height-base);

    &.loginHidden {
        font-weight: 600;
    }
}

.nodeButton {
    width: 100%;
    padding: 0;
    height: auto;

    & span {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-4) 0;
    }

    &:hover svg path {
        stroke: var(--color-primary-600);
    }
}

.nodeLink {
    width: 100%;
    padding: 0;
    height: auto;

    & span {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-4) 0;
    }
}